h1, h2 {
    font-family: "roboto", roboto;
    font-weight: 500;
    color: #068d80;
    /* padding-left: 1rem; */
}

h4, h5 {
    font-family: "Nunito", sans-serif;
    color: #068d80;
    padding-left: 1rem;
    font-size: 1.5rem;
}

p {
    font-family: "Nunito", sans-serif;
    color: #068d80;
    /* padding-left: 1rem; */
    font-size: 1.5rem;
}

.title {
    font-weight: bold;
}

.container {
    margin-left: 13rem;
    margin-right: 13rem;
    background-color: white;
}

.h1 {
    display: flex;
    padding-top: 0.5rem;
}

hr {
    height: 1px;
    border-width: 0;
    background-color: #068d80;
}

.hrBottom {
    margin-bottom: 2rem;
}

.xero {
    display: flex;
    flex-direction: row;
}

.img {
    align-self: center;
}

.img2 {
    align-self: center;
}

.img3 {
    align-self: center;
}

.img4 {
    align-self: center;
    padding-left: 1rem;
}

.logoFlex {
    display: flex;
    align-items: center;
    justify-content: space-between; 
}

.logoFlex2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.italic {
    font-style: italic;
}

.pad {
    padding-left: 2rem;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

a:hover {
    color: black;
}

.padtop {
    padding-top: 1rem;
}

.grey {
    color: #aba6a6;    
}

.bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .img2 {
        display: none;
    }
    .xero {
        flex-direction: column;
    }
    .img {
        width: 100px;
        padding-top: 1rem;
    }
    .pad {
        padding-left: 0;
    }
    .logoFlex {
        flex-direction: column;
        padding: 0;
        margin: 0;
    }
    .logoFlex2 {
        flex-direction: none;
    }
    .bothr {
        display: none;
    }
    .img3 {
        width: 75%;
        padding-bottom: 3rem;
    }
    p {
        font-size: 1rem;
    }
    .img4 {
        display: none;
    }
    .padtop {
        display: none;
    }
}
