.navContainer {
    background: #c1f4eb;
    display: flex;
    align-items: center;
}


nav .inline {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 1.5rem; 
    flex-grow: 1;
    font-family: "roboto", roboto;
    font-size: 1.5rem;
    font-weight: 400;
    color: #068d80;
}


nav a {
    text-decoration: none;
    color: inherit;
    padding: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    white-space: nowrap;

}

.logo {
    padding-left: 1.5rem;
}

.navimg {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

nav .inline a.active {
    background-color: #a6e8db;
}

.pbot {
    padding-bottom: 1rem;
}

.homelink {
    margin: 0;
    padding: 0;
}

/*==========================
hamburger navigation
========================*/

.hamburger {
    position: relative;
    font-family: "roboto", roboto;
    color: #068d80;
    font-weight: 400;
}

button {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 20px;
}

.hamburger .toggle {
    cursor: pointer;
    background: transparent;
    border: none;
}

.menu-nav {
    list-style: none;
    position: absolute;
    background: #fff;
    right: 0;
    width: 0;
    top: 50px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-width: 290px;
    z-index: 9;
    font-size: 18px;
    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
    transform: translateX(200px);
    transition: transform ease-in-out 0.2s;
}
    
.menu-nav.show-menu {
    width: auto;
    transform: translateX(0px);
}

.menu-nav li a,
.menu-nav li {
    display: block;
}

@media (max-width: 768px) {
    nav .inline {
    display: none;
   }
   .navimg {
    display: none;
   }
   .navContainer {
    justify-content: flex-end;
   }
   .hamburger {
    margin-left: auto;
   }
   .logo {
    padding: 0.5rem;
    padding-top: 0.7rem;
   }
}
